import { createCustomProducts } from "./products.api";

export const categories = {
  "6754119335520debb8299aab": [
    "Apple",
    "Banana",
    "Carrot",
    "Broccoli",
    "Avocado",
    "Spinach",
    "Tomato",
    "Potato",
    "Cucumber",
    "Lettuce",
    "Strawberry",
    "Blueberry",
    "Kale",
    "Eggplant",
    "Bell Pepper",
    "Mango",
    "Pear",
    "Orange",
    "Grape",
    "Pineapple",
    "Peach",
    "Plum",
    "Watermelon",
    "Cantaloupe",
    "Raspberries",
    "Blackberries",
    "Zucchini",
    "Asparagus",
    "Cauliflower",
    "Artichoke",
    "Beetroot",
    "Celery",
    "Radish",
    "Turnip",
    "Parsnip",
    "Leek",
    "Garlic",
  ],
  "6754119c35520debb8299aad": [
    "Milk",
    "Cheese",
    "Butter",
    "Yogurt",
    "Mozzarella",
    "Cottage Cheese",
    "Sour Cream",
    "Whipped Cream",
    "Cream Cheese",
    "Greek Yogurt",
    "Almond Milk",
    "Coconut Yogurt",
    "Parmesan",
    "Ricotta",
    "Feta",
    "Gouda",
    "Cheddar",
    "Swiss Cheese",
    "Blue Cheese",
    "Provolone",
    "Brie",
    "Camembert",
    "Manchego",
    "Halloumi",
    "Quark",
    "Mascarpone",
  ],
  "675411a235520debb8299aaf": [
    "Chicken Breast",
    "Ground Beef",
    "Pork Chops",
    "Bacon",
    "Lamb Chops",
    "Turkey",
    "Sausages",
    "Steak",
    "Salami",
    "Duck Breast",
    "Veal",
    "Chorizo",
    "Prosciutto",
    "Bratwurst",
    "Beef Ribs",
    "Pork Belly",
    "Chicken Thighs",
    "Ground Turkey",
    "Chicken Wings",
    "Beef Brisket",
  ],
  "675411a835520debb8299ab1": [
    "Salmon",
    "Shrimp",
    "Tuna",
    "Crab Meat",
    "Salmon Fillet",
    "Crab Legs",
    "Tilapia",
    "Cod",
    "Halibut",
    "Scallops",
    "Sardines",
    "Mackerel",
    "Trout",
    "Mussels",
    "Oysters",
    "Anchovies",
    "Clams",
    "Calamari",
    "Octopus",
    "Lobster",
  ],
  "675411af35520debb8299ab3": [
    "Bread",
    "Croissant",
    "Bagels",
    "Muffins",
    "Brioche",
    "Sourdough",
    "Ciabatta",
    "Rye Bread",
    "Focaccia",
    "Pita",
    "Naan",
    "Tortillas",
    "Challah",
    "Pumpernickel",
    "Multigrain Bread",
    "Flatbread",
    "Baguette",
    "English Muffins",
    "Cornbread",
  ],
  "675411b535520debb8299ab5": [
    "Frozen Pizza",
    "Frozen Fries",
    "Frozen Vegetables",
    "Ice Cream",
    "Fried Chicken",
    "Breaded Shrimp",
    "Frozen Waffles",
    "Frozen Burgers",
    "Frozen Lasagna",
    "Frozen Fish Sticks",
    "Frozen Ravioli",
    "Frozen Burritos",
    "Frozen Mac & Cheese",
    "Frozen Pot Pies",
    "Frozen Meatballs",
    "Frozen Chicken Nuggets",
    "Frozen Spring Rolls",
  ],
  "675411ba35520debb8299ab7": [
    "Coca-Cola",
    "Pepsi",
    "Orange Juice",
    "Water",
    "Sprite",
    "Gatorade",
    "Lemonade",
    "Fruit Punch",
    "Coconut Water",
    "Green Tea",
    "Apple Juice",
    "Iced Tea",
    "Root Beer",
    "Energy Drink",
    "Sparkling Water",
    "Kombucha",
    "Almond Milk",
    "Soy Milk",
  ],
  "675411c035520debb8299ab9": [
    "Chips",
    "Pretzels",
    "Popcorn",
    "Candy Bars",
    "Tortilla Chips",
    "Cheese Puffs",
    "Granola Bars",
    "M&Ms",
    "Doritos",
    "Cheetos",
    "Pringles",
    "Nachos",
    "Trail Mix",
    "Rice Cakes",
    "Beef Jerky",
    "Fruit Snacks",
    "Crackers",
    "Nuts",
    "Sunflower Seeds",
  ],
  "675411c735520debb8299abb": [
    "Canned Tomatoes",
    "Canned Beans",
    "Canned Soup",
    "Canned Corn",
    "Pineapple Chunks",
    "Canned Peas",
    "Canned Carrots",
    "Canned Mushrooms",
    "Canned Tuna",
    "Canned Salmon",
    "Canned Peaches",
    "Canned Pears",
    "Canned Green Beans",
    "Canned Asparagus",
    "Canned Artichoke Hearts",
  ],
  "675411cd35520debb8299abd": [
    "Salt",
    "Pepper",
    "Garlic Powder",
    "Chili Powder",
    "Cumin",
    "Thyme",
    "Cinnamon",
    "Paprika",
    "Oregano",
    "Basil",
    "Rosemary",
    "Sage",
    "Turmeric",
    "Nutmeg",
    "Curry Powder",
    "Cayenne Pepper",
    "Ginger",
    "Dill",
    "Fennel Seeds",
  ],
  "675411d435520debb8299abf": [
    "Oats",
    "Rice",
    "Pasta",
    "Cereal",
    "Quinoa",
    "Couscous",
    "Farro",
    "Barley",
    "Bulgur Wheat",
    "Polenta",
    "Cornmeal",
    "Semolina",
    "Wild Rice",
    "Brown Rice",
    "Arborio Rice",
    "Sushi Rice",
  ],
  "675411da35520debb8299ac1": [
    "Shampoo",
    "Toothpaste",
    "Soap",
    "Conditioner",
    "Deodorant",
    "Shaving Cream",
    "Hand Sanitizer",
    "Body Wash",
    "Face Wash",
    "Moisturizer",
    "Sunscreen",
    "Hair Gel",
    "Hair Spray",
    "Body Lotion",
    "Makeup Remover",
    "Facial Cleanser",
  ],
  "675411e135520debb8299ac3": [
    "Bleach",
    "Dish Soap",
    "All-purpose Cleaner",
    "Wipes",
    "Laundry Detergent",
    "Dishwashing Liquid",
    "Glass Cleaner",
    "Floor Cleaner",
    "Toilet Cleaner",
    "Disinfectant Spray",
    "Fabric Softener",
    "Stain Remover",
    "Mop",
    "Sponges",
  ],
  "675411e835520debb8299ac5": [
    "Dog Food",
    "Cat Food",
    "Bird Seed",
    "Fish Food",
    "Dog Leash",
    "Cat Litter",
    "Bird Cage",
    "Fish Tank",
    "Pet Shampoo",
    "Pet Treats",
    "Cat Scratching Post",
    "Dog Collar",
    "Pet Brush",
    "Pet Toys",
    "Aquarium Decorations",
  ],
  "675411ee35520debb8299ac7": [
    "Vitamin C",
    "Multivitamins",
    "Aspirin",
    "Cough Syrup",
    "Iron Supplement",
    "Probiotics",
    "Pain Relievers",
    "Cold Medicine",
    "Allergy Medicine",
    "Antacids",
    "Sleep Aid",
    "Vitamin D",
    "Calcium Supplements",
    "Omega-3",
    "Melatonin",
  ],
  "675411f535520debb8299ac9": [
    "Baby Formula",
    "Baby Wipes",
    "Diapers",
    "Baby Lotion",
    "Baby Shampoo",
    "Baby Powder",
    "Baby Bottles",
    "Pacifiers",
    "Diaper Rash Cream",
    "Baby Oil",
    "Baby Soap",
    "Teething Gel",
    "Baby Sunscreen",
  ],
  "675411fc35520debb8299acb": [
    "Toothbrush",
    "Tooth Floss",
    "Mouthwash",
    "Deodorant",
    "Dental Picks",
    "Tongue Cleaner",
    "Dental Floss Picks",
    "Electric Toothbrush",
    "Teeth Whitening Strips",
  ],
  "6754120235520debb8299acd": [
    "Smartphone",
    "Laptop",
    "Tablet",
    "Headphones",
    "Smartwatch",
    "Bluetooth Speaker",
    "Laptop Charger",
    "Power Bank",
    "Wireless Earbuds",
    "Camera",
    "Smart Home Device",
    "Gaming Console",
    "External Hard Drive",
    "Monitor",
    "Router",
  ],
  "6754120935520debb8299acf": [
    "T-shirt",
    "Jeans",
    "Jacket",
    "Sneakers",
    "Sweater",
    "Shorts",
    "Hats",
    "Socks",
    "Underwear",
    "Pajamas",
    "Sweatpants",
    "Dress Shirt",
    "Blazer",
    "Rain Jacket",
    "Running Shoes",
    "Boots",
    "Sandals",
    "Scarf",
    "Gloves",
  ],
  "6754121035520debb8299ad1": [
    "Basketball",
    "Football",
    "Tennis Racket",
    "Soccer Ball",
    "Baseball Bat",
    "Badminton Set",
    "Volleyball",
    "Golf Clubs",
    "Hockey Stick",
    "Baseball Glove",
    "Tennis Balls",
    "Bicycle",
    "Skateboard",
    "Running Shoes",
    "Yoga Mat",
  ],
};

export const test123 = async () => {
  for (const cat of Object.keys(categories)) {
    const pro = categories[cat as keyof typeof categories];
    for (const product of pro) {
      await createCustomProducts({
        name: product,
        type: cat,
      });
    }
  }
};
