import { MdAddReaction, MdEdit } from "react-icons/md";
import { Avatar, Button, Flex } from "@chakra-ui/react";

import { GRAY_ICON_COLOR } from "../../../../variables/colors.variables";
import { HOME_PATH } from "../../../../variables/paths.variables";
import { createShoppingListInvitation } from "../../../../api/invitation/invitation.api";

type HeaderOptionsProps = {
  shoppingListDocumentReference: string;
  sharedWith: {
    email: string;
    id: string;
    username: string;
  }[];
};

const HeaderOptions = ({
  shoppingListDocumentReference,
  sharedWith,
}: HeaderOptionsProps) => {
  const onShareListClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      const response = await createShoppingListInvitation({
        listReference: shoppingListDocumentReference,
      });

      const shareData = {
        title: "Carttie",
        text: "Hi! Join to my shopping list",
        url: `${HOME_PATH}invite-accept/?id=${response.data._id}`,
      };

      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        console.log(shareData.url); // Display link in console when there is no https connection (localhost)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const amountOfSharedPeople = sharedWith.length;
  const maxOfShowedPeople = 5;
  const additional = amountOfSharedPeople - maxOfShowedPeople;

  return (
    <Flex h="16px" position="absolute" right="10px" top="15px">
      <Flex alignItems="center" h="40px">
        {sharedWith.slice(0, maxOfShowedPeople).map((user) => (
          <Avatar name={user?.username} size="xs" mr="-10px" />
        ))}
        {additional > 0 && (
          <Flex
            borderRadius={100}
            bg="white"
            border="solid 1px"
            w="24px"
            h="24px"
            alignItems="center"
            justifyContent="center"
            fontSize="10px"
          >
            +{additional}
          </Flex>
        )}
        <Button ml={5} bg="none" p="0px" onClick={onShareListClick}>
          <MdAddReaction size={16} color={GRAY_ICON_COLOR} />
        </Button>
      </Flex>
    </Flex>
  );
};

export default HeaderOptions;
