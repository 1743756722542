import { forwardRef, useContext, useEffect, useMemo, useRef } from "react";
import { useClickAway } from "@uidotdev/usehooks";
import { MdCheck } from "react-icons/md";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { CheckIcon } from "@chakra-ui/icons";
import beepSound from "../../../assets/beepSound.mp3";

import NewListForm from "../new-list-form";
import "./index.scss";
import { AppContext } from "../../../app/contexts/appState.context";
import ProductSearch from "../product-search";
import FullscreenButton from "./components/fullscreen-button";
import NoProducts from "./components/no-products";
import { prepProductsToDisplay } from "./utils/prepProductsToDisplay";
import { ShoppingListElementGroup } from "./components/shopping-list-element-group";
import { ShoppingListDTO } from "../../api/shoppingLists/shopping-lists.api.type";
import { bulkUpdateProductsInTheList } from "../../api/productInList/product-in-list.api";
import { deleteShoppingList } from "../../api/shoppingLists/shopping-lists.api";
import { getProductByID } from "../../api/products.api";
import { useAppState } from "../../../app.state";
import { useShoppingListState } from "./shoppint-list.state";
import { useShoppingList } from "./use-shopping-list";
import { ShoppingListHeader } from "./components/shopping-list-header/shopping-list-header";
import useSound from "use-sound";
import {
  ProductCategoriesIdMapper,
  ProductNamesMapper,
} from "../../variables/product-types.variables";

type ShoppingListProps = {
  data: ShoppingListDTO;
  sendMessage: (message: any) => void;
  refetchLists: () => Promise<void>;
};

const ShoppingList = forwardRef(
  (
    { data, sendMessage, refetchLists }: ShoppingListProps,
    refForwarded
  ): JSX.Element => {
    // Helpers
    const { appState, dispatch: dispatchAppState } = useContext(AppContext);
    // States
    const {
      isFocused,
      setIsFocused,
      isFullscreenActive,
      setIsFullScreenActive,
      isAddProductModeActive,
      setIsAddProductModeActive,
      newProductsInList,
      setNewProductsInList,
      isEditListModeOn,
      setIsEditListModeOn,
      internalProductInList,
      setInternalProductsInList,
    } = useShoppingList(data.products);

    // Atoms
    const { products, setProducts, user } = useAppState();
    const {
      productsInShoppingList,
      setProductsInShoppingList,
      productSearchChanges,
      setProductSearchChanges,
    } = useShoppingListState();

    useEffect(() => {
      if (data.products && (isFocused || isFullscreenActive)) {
        const getMissingProducts = async () => {
          const productsPromises: Promise<any>[] = [];
          data.products.map((productInList) => {
            if (
              !products.find(
                (product) => product.id === productInList.productReference
              )
            ) {
              productsPromises.push(
                getProductByID(productInList.productReference)
              );
            }
          });

          return Promise.all(productsPromises);
        };

        getMissingProducts().then((results: any) => {
          if (results.length) {
            setProducts((prev) => [
              ...prev,
              ...results.map((r: any) => r?.data),
            ]);
          }
          setProductsInShoppingList(data.products);
          setInternalProductsInList(data.products);
        });
      }
    }, [
      isFocused,
      data.products,
      isFullscreenActive,
      setProductsInShoppingList,
    ]);

    const { countOfProductsLeft, checkedOutProducts, productsLeft } = useMemo(
      () => prepProductsToDisplay(internalProductInList, products),
      [internalProductInList, products, data.products]
    );

    useEffect(() => {
      if (isFullscreenActive) {
        dispatchAppState({
          type: "SET_FLOATING_BUTTON",
          data: {
            ...appState.floatingButtonState,
            icon: isAddProductModeActive ? <CheckIcon boxSize={4} /> : null,
            callback: () => {
              if (isAddProductModeActive) {
                updateProductsInList();
                closeProductAddingMode();
              } else {
                setIsAddProductModeActive(true);
              }
            },
          },
        });
      }

      if (!isFullscreenActive) {
        refetchLists();
        setProductsInShoppingList([]);
      }
    }, [isFullscreenActive, isAddProductModeActive]);

    const [playSound] = useSound(beepSound, { id: "playback" });

    const ref: any = useClickAway(() => {
      if (isFocused && !isFullscreenActive) {
        setIsFocused(false);
        setIsFullScreenActive(false);
        setProductsInShoppingList([]);
        dispatchAppState({
          type: "SET_LIST_IS_UN_OPEN",
        });
      }
    });

    const sendUpdateInformation = () => {
      sendMessage(
        JSON.stringify({
          id: data.id,
          uid: user.uid,
          sharedWith: [...data.shared_with, data.created_by],
        })
      );
    };

    const onListDelete = async () => {
      try {
        await deleteShoppingList({ listReference: data.id });
        // Hide shopping list after delete
        ref.current.style.display = "none";
      } catch {}
    };

    const updateProductsInList = async () => {
      const payload = {
        listReference: data.id,
        added: Array.from(productSearchChanges.added),
        deleted: Array.from(productSearchChanges.deleted),
      };
      const newProducts = await bulkUpdateProductsInTheList(payload);
      setProductsInShoppingList(newProducts.data);
      setInternalProductsInList(newProducts.data);
      await setProductSearchChanges({
        deleted: new Set<string>(),
        added: new Set<string>(),
      });
      sendUpdateInformation();
    };

    const closeProductAddingMode = () => {
      setNewProductsInList({});
      setIsAddProductModeActive(false);
    };

    return (
      <div
        className={`ShoppingList ${isFocused ? "focused" : ""} ${
          isFullscreenActive ? "fullscreen" : ""
          //@ts-expect-error it is
        }${window?.Capacitor ? " mobileApp" : ""}`}
        ref={ref}
        onClick={(e) => {
          setIsFullScreenActive(true);
          dispatchAppState({
            type: "SET_LIST_IS_OPEN",
          });
          setIsFocused(true);
        }}
      >
        <ShoppingListHeader
          setIsEditListModeOn={setIsEditListModeOn}
          setIsFullScreenActive={setIsFullScreenActive}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          setIsAddProductModeActive={setIsAddProductModeActive}
          listData={data}
          onDelete={onListDelete}
        />
        {!isFocused && (
          <span className="ShoppingList__elementsLeft">
            {productsLeft && data.products.length ? (
              countOfProductsLeft || (
                <span className="ShoppingList__elementsLeft__green">
                  <MdCheck size={15} />
                </span>
              )
            ) : (
              <span className="ShoppingList__elementsLeft__red">-</span>
            )}
          </span>
        )}
        <div className="ShoppingList__elementsHolders">
          {!isAddProductModeActive && isFocused && (
            <>
              <div className="ShoppingList__elementsHolder">
                {Object.keys(productsLeft).length > 0 && (
                  <div className="ShoppingList__elementsHolder__groupHolder">
                    {Object.keys(productsLeft)?.map((productType) => {
                      return (
                        <ShoppingListElementGroup
                          key={productType}
                          playSound={playSound}
                          sendMessage={sendUpdateInformation}
                          categoryName={
                            ProductNamesMapper[
                              ProductCategoriesIdMapper[
                                productType as keyof typeof ProductCategoriesIdMapper
                              ]
                            ]
                          }
                          products={productsLeft[productType]}
                          productsInShoppingList={productsInShoppingList as any}
                          shoppingListRef={data.id}
                          setInternalProductsInList={setInternalProductsInList}
                        />
                      );
                    })}
                  </div>
                )}
                {Object.keys(checkedOutProducts).length > 0 && (
                  <div className="ShoppingList__elementsHolder__checkedOut">
                    {Object.keys(checkedOutProducts).map((productType) => {
                      return (
                        <ShoppingListElementGroup
                          key={productType}
                          playSound={playSound}
                          sendMessage={sendUpdateInformation}
                          categoryName={
                            ProductNamesMapper[
                              ProductCategoriesIdMapper[
                                productType as keyof typeof ProductCategoriesIdMapper
                              ]
                            ]
                          }
                          products={checkedOutProducts[productType]}
                          productsInShoppingList={productsInShoppingList as any}
                          shoppingListRef={data.id}
                          setInternalProductsInList={setInternalProductsInList}
                        />
                      );
                    })}
                  </div>
                )}
                {Object.keys(checkedOutProducts).length <= 0 &&
                  Object.keys(productsLeft).length <= 0 && (
                    <NoProducts
                      areTheyCheckoutProducts={
                        Object.keys(checkedOutProducts).length > 0
                      }
                    />
                  )}
              </div>
              <FullscreenButton
                isFullscreenActive={isFullscreenActive}
                setIsFullScreenActive={setIsFullScreenActive}
                setIsFocused={setIsFocused}
              />
            </>
          )}
        </div>
        {isAddProductModeActive &&
          isFullscreenActive &&
          productsInShoppingList && (
            <ProductSearch
              newProductsInList={newProductsInList}
              setNewProductsInList={setNewProductsInList}
            />
          )}
        {isEditListModeOn && (
          <NewListForm
            isOpen={true}
            setIsNewListFormOpen={() => setIsEditListModeOn(false)}
            currentListData={data}
          />
        )}
        <button
          className="ShoppingList__previewButton"
          onClick={(e) => {
            e.stopPropagation();
            setIsFocused(!isFocused);
            //@ts-expect-error dsds
            if (refForwarded?.current) {
              const srcollToCalc = ref.current.offsetTop * 0.8;

              // We need to wait for scroll box to arise
              setTimeout(() => {
                //@ts-expect-error das
                refForwarded.current.scroll(0, srcollToCalc);
              }, 190);
            }
          }}
        >
          {isFocused ? <FaArrowUp size={10} /> : <FaArrowDown size={10} />}
        </button>
      </div>
    );
  }
);

export default ShoppingList;
