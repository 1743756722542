import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { Box, Button, Flex, Text } from "@chakra-ui/react";

import { ProductInListDTO } from "../../../../api/productInList/product-in-list.api.type";
import { ProductsDTO } from "../../../../api/products.api.type";
import { useMemo, useState } from "react";
import { ICONS } from "../../../../variables/product-types.variables";
import useSound from "use-sound";
import beepSound from "./../../../../../assets/beepSound.mp3";
import { useShoppingListState } from "../../shoppint-list.state";
import { useTranslation } from "react-i18next";

type ProductSearchElementProps = {
  productData: ProductsDTO;
};

export const ProductSearchElement = ({
  productData,
}: ProductSearchElementProps) => {
  const { t } = useTranslation();
  const [playSound] = useSound(beepSound);
  // Atoms
  const {
    productsInShoppingList,
    productSearchChanges,
    setProductSearchChanges,
  } = useShoppingListState();

  const [productHasBeenAdded, setProductHasBeenAdded] = useState<
    boolean | null
  >(null);

  const isProductInTheList = useMemo(() => {
    if (productHasBeenAdded !== null) return productHasBeenAdded;
    let isInTheList = false;
    productsInShoppingList.forEach((product: ProductInListDTO) => {
      if (product.productReference === productData.id) {
        return (isInTheList = true);
      }
    });

    productSearchChanges.added.forEach((prodID: string) => {
      if (prodID === productData.id) {
        return (isInTheList = true);
      }
    });
    return isInTheList;
  }, [productsInShoppingList, productData.id, productHasBeenAdded]);

  const handleProductClick = () => {
    playSound();
    const update = { ...productSearchChanges };
    if (isProductInTheList) {
      if (update.added.has(productData.id)) {
        update.added.delete(productData.id);
      } else {
        update.deleted.add(productData.id);
      }
      setProductHasBeenAdded(false);
    } else {
      if (update.deleted.has(productData.id)) {
        update.deleted.delete(productData.id);
      } else {
        update.added.add(productData.id);
      }
      setProductHasBeenAdded(true);
    }
    setProductSearchChanges(update);
  };

  return (
    <Flex
      w="100%"
      height="40px"
      alignItems="center"
      gap="16px"
      position="relative"
      textTransform="capitalize"
    >
      <>
        {ICONS?.[productData.type]}
        <Text>{t(productData.name)}</Text>
      </>
      <Button
        bg={isProductInTheList ? "red.400" : "green.400"}
        color="white"
        size="sm"
        onClick={handleProductClick}
        _hover={{ bg: isProductInTheList ? "red.400" : "green.400" }}
        position="absolute"
        right="15px"
        className="ProductSearch__productsHolder__product__addButton"
      >
        {isProductInTheList ? (
          <CiCircleMinus size={20} color="white" />
        ) : (
          <CiCirclePlus size={20} color="white" />
        )}
      </Button>
    </Flex>
  );
};
