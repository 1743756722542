import { useLongPress } from "@uidotdev/usehooks";
import { Button, Flex, Text } from "@chakra-ui/react";

import { ProductInListDTO } from "../../../../api/productInList/product-in-list.api.type";
import { updateProductInList } from "../../../../api/productInList/product-in-list.api";
import { useTranslation } from "react-i18next";
import { UnitHolder } from "../unit-holder";
import { motion, useIsPresent } from "framer-motion";
import { ProductEditModal } from "../product-edit-modal";
import { useShoppingListElement } from "./use-shopping-list-element";
import { useShoppingListState } from "../../shoppint-list.state";
import { useProduct } from "../../../../hooks/use-product";
import { PlayFunction } from "use-sound/dist/types";

type ShoppingListElementProps = {
  productData: ProductInListDTO;
  shoppingListRef?: string;
  sendMessage: any;
  setInternalProductsInList: (newProductsInList: ProductInListDTO[]) => any;
  playSound: PlayFunction;
};

/** Component used for displaying product inside shopping list (normal mode) */
const ShoppingListElement = ({
  productData,
  sendMessage,
  shoppingListRef,
  playSound,
  setInternalProductsInList,
}: ShoppingListElementProps) => {
  // Helpers
  const { t } = useTranslation();

  // States
  const {
    internalProductState,
    setInternalProductState,
    isEditModalShown,
    closeModal,
    openModal,
  } = useShoppingListElement({ productData });

  const { productsInShoppingList, setProductsInShoppingList } =
    useShoppingListState();
  const { productToUse } = useProduct({ productData });

  const updateAtomsProducts = (
    key: keyof ProductInListDTO,
    value: number | string | boolean
  ) => {
    playSound();
    const newProductsInList = [...productsInShoppingList].map((item) => {
      if (productData?.id === item.id) return { ...item, [key]: value };
      else return item;
    });
    setProductsInShoppingList(newProductsInList);
    setInternalProductsInList(newProductsInList);
  };

  const attrs = useLongPress(
    () => {
      closeModal();
    },
    {
      onStart: (event) => "Press started",
      onFinish: (event) => "Press Finished",
      onCancel: (event) => "Press cancelled",
      threshold: 50,
    }
  );

  const variants = {
    rotate: {
      textDecoration: "line-through",
      transition: { duration: 6 },
    },
  };

  const isPresent = useIsPresent();
  const animations = {
    style: {
      position: isPresent ? "static" : "absolute",
    },
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
    transition: { type: "spring", stiffness: 900, damping: 40 },
  };

  if (!productToUse) {
    return null;
  }

  return (
    // @ts-expect-error 123
    <Flex
      w="100%"
      height="40px"
      alignItems="center"
      gap="16px"
      padding="0 10px"
      textTransform="capitalize"
      justifyContent="space-between"
      as={motion.div}
      {...animations}
      layout
      position="relative"
    >
      <Flex alignItems="center" gap="10px">
        <Text minW="30px">{internalProductState?.quantity}x</Text>
        <UnitHolder unit={internalProductState?.unit ?? t("pcs")} />
        <Flex flexDirection="column">
          <Text
            as={motion.span}
            variants={variants}
            animate={internalProductState?.isChecked ? "rotate" : "stop"}
            initial="hidden"
            onClick={() => {
              openModal();
            }}
          >
            {internalProductState?.alterName ??
              t(productToUse.name) ??
              "unknown"}
          </Text>
          {internalProductState?.alterName && (
            <Text
              as={motion.span}
              variants={variants}
              animate={internalProductState?.isChecked ? "rotate" : "stop"}
              initial="hidden"
              onClick={openModal}
              fontSize="11px"
              color="gray"
              font-weight="700"
            >
              {t(productToUse.name) ?? "unknown"}
            </Text>
          )}
        </Flex>
      </Flex>
      <Button
        w="32px"
        h="32px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        overflow="visible"
        bg="none"
        marginRight="15px"
        border="solid 1px"
        minW="32px"
        p="0"
        onClick={async (e) => {
          if (productData) {
            e.stopPropagation();
            const newIsChecked = !productData?.isChecked;
            //@ts-expect-error 123
            setInternalProductState({ ...internalProductState, started: true });
            updateAtomsProducts("isChecked", newIsChecked);
            await updateProductInList({
              isChecked: newIsChecked,
              productReference: productData?.id,
              listReference: shoppingListRef,
            });
            sendMessage();
          }
        }}
      >
        {internalProductState?.isChecked && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            width={50} // for safari
            stroke="currentColor"
          >
            <motion.path
              strokeLinecap="round"
              strokeLinejoin="round"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 1 }}
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        )}
      </Button>
      <ProductEditModal
        isOpen={isEditModalShown}
        onClose={closeModal}
        productData={productData}
        shoppingListRef={shoppingListRef}
        internalProductState={internalProductState}
        setInternalProductState={setInternalProductState}
      />
    </Flex>
  );
};

export default ShoppingListElement;
