import { useState } from "react";
import { updateProductInList } from "../../../../api/productInList/product-in-list.api";
import { ProductInListDTO } from "../../../../api/productInList/product-in-list.api.type";

type UseProductEditProps = {
  productData: any;
  shoppingListRef: any;
  internalProductState: any;
};

export const useProductEdit = ({
  productData,
  shoppingListRef,
  internalProductState,
}: UseProductEditProps) => {
  const [modalProductData, setModalProductData] =
    useState(internalProductState);

  const patchProduct = async (product: any) => {
    try {
      const productResponse = await updateProductInList({
        productReference: productData?.id,
        listReference: shoppingListRef,
        alterName: product?.alterName ?? "",
        quantity: product?.quantity ?? 1,
        unit: product?.unit ?? "",
      });
      return productResponse.data;
    } catch (error) {}
  };

  return {
    patchProduct,
    modalProductData,
    setModalProductData,
  };
};
