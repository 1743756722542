import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "@chakra-ui/react";

import { Router } from "./app/router";
import { getCurrentUserInformation } from "./shared/api/user-account/user.api";
import "./App.scss";
import { preventBrowserHistorySwipeGestures } from "./preventBrowserHistorySwipeGestures";
import { useAppState } from "./app.state";

function App() {
  // Helpers
  const { i18n } = useTranslation();
  // States
  const [isLoading, setIsLoading] = useState(true);
  // Atoms
  const { setUser } = useAppState();

  useEffect(() => {
    setIsLoading(true);
    // TODO: Change that later, Set lang by def to polish for now
    i18n.changeLanguage("pl");
    preventBrowserHistorySwipeGestures();
    const token = localStorage.getItem("accessToken");
    if (!token) {
      setIsLoading(false);
    }
    if (token) {
      getCurrentUserInformation()
        .then((res) => {
          setUser(res.data);
          return res;
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [i18n]);

  if (isLoading) {
    return (
      <div className="App loading">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
