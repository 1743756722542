import { Flex } from "@chakra-ui/react";

type UnitHolderProps = {
  unit: string;
};

export const UnitHolder = ({ unit }: UnitHolderProps): JSX.Element => {
  return (
    <Flex
      bg="brand.200"
      px="8px"
      alignItems="center"
      justifyContent="center"
      color="brandDark.500"
      fontSize="12px"
      height="23px"
      borderRadius="5px"
    >
      {unit ?? ""}
    </Flex>
  );
};
