import { atom, SetterOrUpdater } from "recoil";

import { useRecoilState } from "recoil";
import { ProductsGET } from "./shared/api/products.api.type";

/**
 * Holds a default products lookup
 */
export const ProductsAtom = atom({
  key: "ProductsAtom",
  default: [] as ProductsGET,
});

type UserInfo = {
  full_name: string;
  uid: string;
};

export const UserInfoAtom = atom({
  key: "UserInfoAtom",
  default: {} as UserInfo,
});

type UseAppStateReturn = {
  products: ProductsGET;
  setProducts: SetterOrUpdater<ProductsGET>;
  user: UserInfo;
  setUser: SetterOrUpdater<UserInfo>;
};

export const useAppState = (): UseAppStateReturn => {
  const [products, setProducts] = useRecoilState(ProductsAtom);
  const [user, setUser] = useRecoilState(UserInfoAtom);

  return { products, setProducts, user, setUser };
};
