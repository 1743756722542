import { AnimatePresence } from "framer-motion";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import {
  ICONS,
  ProductCategoriesIdMapper,
} from "../../../../variables/product-types.variables";
import { ProductInListDTO } from "../../../../api/productInList/product-in-list.api.type";
import ShoppingListElement from "../shopping-list-element";
import { useProduct } from "../../../../hooks/use-product";
import { PlayFunction } from "use-sound/dist/types";

type ShoppingListElementGroupProps = {
  categoryName: string;
  products: ProductInListDTO[];
  sendMessage: any;
  playSound: PlayFunction;
  productsInShoppingList: {
    [key: string]: ProductInListDTO;
  };
  shoppingListRef?: string;
  setInternalProductsInList: (newProductsInList: ProductInListDTO[]) => any;
};

export const ShoppingListElementGroup = ({
  categoryName,
  products,
  sendMessage,
  playSound,
  shoppingListRef,
  setInternalProductsInList,
}: ShoppingListElementGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const { productToUse } = useProduct({ productData: products[0] });

  return (
    <Box minW="100%">
      <Text
        position="sticky"
        top="0"
        zIndex="1"
        bg="brandDark.500"
        color="white"
        padding="10px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
        textTransform="capitalize"
        minW="100%"
      >
        <Flex mr={2}>
          {
            ICONS[
              // @ts-expect-error t
              ProductCategoriesIdMapper[
                productToUse?.type ?? ""
              ] as keyof typeof ProductCategoriesIdMapper
            ]
          }
        </Flex>
        {t(categoryName)}
      </Text>
      <AnimatePresence>
        {products?.map((product) => (
          <ShoppingListElement
            key={product.id}
            playSound={playSound}
            sendMessage={sendMessage}
            productData={product}
            shoppingListRef={shoppingListRef}
            setInternalProductsInList={setInternalProductsInList}
          />
        ))}
      </AnimatePresence>
    </Box>
  );
};
