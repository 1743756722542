import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductEdit from "../product-edit";
import NewProduct from "./Components/NewProductSearchInput";
import { FilteredProduct } from "./types";
import { Box, Flex, Input, Spinner } from "@chakra-ui/react";
import { ProductSearchElement } from "../shopping-list/components/product-search-element";
import { createCustomProducts } from "../../api/products.api";
import { ProductInListDTO } from "../../api/productInList/product-in-list.api.type";
import { partition } from "./utils";
import { useAppState } from "../../../app.state";
import { useShoppingListState } from "../shopping-list/shoppint-list.state";

type ProductSearchProps = {
  newProductsInList: any;
  setNewProductsInList: any;
};

const ProductSearch = ({
  newProductsInList,
  setNewProductsInList,
}: ProductSearchProps) => {
  const { t } = useTranslation();
  const searchInput = useRef<HTMLInputElement | null>(null);

  // States
  const [productSearchValue, setProductSearchValue] = useState<string>("");
  const [showNewProductModal, setShowNewProductModal] =
    useState<boolean>(false);

  // Atoms
  const { products, setProducts } = useAppState();
  const {
    productsInShoppingList,
    productSearchChanges,
    setProductSearchChanges,
  } = useShoppingListState();

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.focus();
    }
  }, []);

  /**
   * Returns filtered products based on user input search
   */
  const filteredProducts = useMemo((): FilteredProduct[] => {
    const mergeProducts = {
      ...products,
      ...newProductsInList,
    };
    return (
      Object.keys(mergeProducts)
        .map((productID) => {
          return {
            id: productID,
            ...mergeProducts[productID],
          };
        })
        .filter((productObject) =>
          t(productObject.name)
            .toLowerCase()
            .includes(productSearchValue.toLowerCase())
        ) ?? []
    );
  }, [products, productSearchValue, newProductsInList]);

  const handleNewProductClick = (productData: any) => {
    const update = { ...productSearchChanges };
    if (update.deleted.has(productData.id)) {
      update.deleted.delete(productData.id);
    } else {
      update.added.add(productData.id);
    }
    setProductSearchChanges(update);
  };

  if (!products) {
    return <Spinner />;
  }

  const isProductInTheList = (productData: any) => {
    let isInTheList = false;
    productsInShoppingList.forEach((product: ProductInListDTO) => {
      if (product.productReference === productData.id) {
        return (isInTheList = true);
      }
    });

    if (!isInTheList) {
      productSearchChanges.added.forEach((prodID: string) => {
        if (prodID === productData.id) {
          return (isInTheList = true);
        }
      });
    }
    return isInTheList;
  };

  const memoizeProducts = useMemo(() => {
    const [inTheList, notInTheList] = partition(
      filteredProducts,
      isProductInTheList
    );

    return (
      <>
        {notInTheList
          //@ts-expect-error ds
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((product: any) => (
            <ProductSearchElement key={product.id} productData={product} />
          ))}
        {inTheList
          //@ts-expect-error ds
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((product: any) => (
            <ProductSearchElement key={product.id} productData={product} />
          ))}
      </>
    );
  }, [products, filteredProducts]);

  return (
    <Box w="90%" margin="10px auto">
      <Input
        value={productSearchValue}
        placeholder={t("Search product e.g. Carrot")}
        onChange={(e) => setProductSearchValue(e.target.value)}
      />
      <Flex
        flexWrap="wrap"
        gap="5px"
        margin="15px 0"
        overflow="scroll"
        maxHeight="100vh"
        paddingBottom="320px"
      >
        {productSearchValue && !filteredProducts.length && (
          <NewProduct
            productSearchValue={productSearchValue}
            setShowNewProductModal={setShowNewProductModal}
          />
        )}
        {memoizeProducts}
      </Flex>
      {showNewProductModal && (
        <ProductEdit
          isOpen={showNewProductModal}
          onProductSave={async (product: any) => {
            const createdProduct = await createCustomProducts({
              name: product.name,
              type: product.category,
            });
            handleNewProductClick(createdProduct.data);
            // @ts-expect-error l
            setProducts((prev) => [...prev, createdProduct.data]);
            setProductSearchValue("");
          }}
          closeModalFunc={() => setShowNewProductModal(false)}
          startNameValue={productSearchValue}
        />
      )}
    </Box>
  );
};

export default ProductSearch;
