import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  PinInput,
  PinInputField,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { endOTP, startOTP } from "../../../shared/api/otp/otp.api";
import { isEmailValid } from "../../../shared/utils/utils";
import { AxiosError } from "axios";
import { getCurrentUserInformation } from "../../../shared/api/user-account/user.api";
import { useAppState } from "../../../app.state";

export const Auth = () => {
  // Helpers
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  // States
  const [email, setEmail] = useState<string>(location.state?.email || "");
  const [userGivenOTP, setUserGivenOTP] = useState<string>(
    location.state?.otp || ""
  );
  const [OTPRequestSend, setOTPRequestSend] = useState(!!location.state?.otp);
  const [isFormError, setIsFormError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Atoms
  const { setUser } = useAppState();

  const signIn = async () => {
    setIsLoading(true);
    try {
      // If otp is empty string it means that this is first step
      if (!userGivenOTP) {
        const res = await startOTP({ email });
        setOTPRequestSend(true);
        setIsLoading(false);
        // TODO: Remove toast when email otp will be working
        toast({
          title: "OTP",
          description: `DEV MODE ${res.data.split(" ").at(-1)}`,
          status: "success",
          duration: 90000,
          isClosable: true,
        });
      }

      const tokenResponse = await endOTP({ email, OTP: userGivenOTP });
      localStorage.setItem("accessToken", tokenResponse.data.accessToken);
      localStorage.setItem("refreshToken", tokenResponse.data.refreshToken);

      const userData = await getCurrentUserInformation();
      setUser(userData.data);

      toast.closeAll();
      const redirectLink = localStorage.getItem("redirect");

      if (redirectLink) {
        localStorage.removeItem("redirect");
        navigate(redirectLink);
        return;
      }

      navigate("/");
    } catch (error: unknown) {
      if (
        error instanceof AxiosError &&
        error?.response?.data === "User not found"
      ) {
        navigate("/terms", { state: { email } });
      }
      setIsFormError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const handleFormOnEnter = (e: KeyboardEvent) =>
      e.key === "Enter" && signIn();

    document.addEventListener("keydown", handleFormOnEnter);

    return () => {
      document.removeEventListener("keydown", handleFormOnEnter);
    };
  }, [signIn]);

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        w="100%"
        flexWrap="wrap"
        gap="5px"
      >
        <Text fontSize="26px" w="100%" pb="30px">
          {t("Sign up / Log in")}
        </Text>
        {!OTPRequestSend ? (
          <FormControl isInvalid={isFormError}>
            <FormLabel>{t("Email address")}</FormLabel>
            <Input
              value={email}
              onInput={(e) => {
                setIsFormError(isEmailValid(email));
                setEmail(e.currentTarget.value);
              }}
              onBlur={() => setIsFormError(isEmailValid(email))}
              type="email"
              placeholder="email@example.com"
            />
            {!isFormError ? (
              <FormHelperText>We'll never share your email.</FormHelperText>
            ) : (
              <FormErrorMessage>Email is invalid.</FormErrorMessage>
            )}
          </FormControl>
        ) : (
          <HStack>
            <PinInput otp size="md" onChange={(val) => setUserGivenOTP(val)}>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
        )}
      </Flex>
      <Button
        isLoading={isLoading}
        //isDisabled={isFormError || !email}
        w="80%"
        bg="brand.500"
        color="white"
        mt="20px"
        onClick={signIn}
      >
        {t("Continue")}
      </Button>
    </>
  );
};
