import { Box, Flex, Input, Portal, Slide, Text } from "@chakra-ui/react";
import ModalActionButtons from "../../../modal-action-buttons";
import { useTranslation } from "react-i18next";
import { useProductEdit } from "./use-product-edit";
import { useClickAway } from "@uidotdev/usehooks";

type ProductEditModalProps = {
  isOpen: boolean;
  productData: any;
  shoppingListRef: any;
  internalProductState: any;
  setInternalProductState: any;
  onClose: () => void;
};

export const ProductEditModal = ({
  isOpen,
  productData,
  internalProductState,
  shoppingListRef,
  setInternalProductState,
  onClose,
}: ProductEditModalProps) => {
  const { t } = useTranslation();
  const { patchProduct, modalProductData, setModalProductData } =
    useProductEdit({ productData, shoppingListRef, internalProductState });

  const ref: any = useClickAway(() => {
    onClose();
  });

  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <Slide
        direction="bottom"
        in={isOpen}
        style={{ zIndex: 1001 }}
        unmountOnExit={true}
      >
        <Box
          ref={ref}
          display="flex"
          justifyContent="center"
          p="35px"
          color="brandDark.500"
          mt="4"
          rounded="md"
          bg="white"
          boxShadow="3px 2px 10px -5px #6e6e6e"
        >
          <Flex flexWrap="wrap" maxW="320px">
            <Text mb="8px">{t("Own Name")}</Text>
            <Input
              mb="20px"
              value={modalProductData?.alterName ?? ""}
              onChange={(e) => {
                setModalProductData((prev: any) => ({
                  ...prev,
                  alterName: e?.target?.value,
                }));
              }}
            />
            <Flex mb="20px" gap="20px" justifyContent="space-between">
              <Flex w="50%" flexWrap="wrap">
                <Text w="100%" mb="8px">
                  {t("Quantity")}
                </Text>
                <Input
                  defaultValue={modalProductData?.quantity}
                  type="number"
                  onChange={(e) => {
                    setModalProductData((prev: any) => ({
                      ...prev,
                      quantity: e.target.value ?? 0,
                    }));
                  }}
                />
              </Flex>
              <Flex w="50%" flexWrap="wrap">
                <Text w="100%" mb="8px">
                  {t("Unit")}
                </Text>
                <Input
                  value={modalProductData?.unit ?? ""}
                  onChange={(e) => {
                    setModalProductData((prev: any) => ({
                      ...prev,
                      unit: e.target.value ?? "",
                    }));
                  }}
                />
              </Flex>
            </Flex>
            <ModalActionButtons
              actionLabel={t("Save")}
              onCancel={onClose}
              onAction={async () => {
                try {
                  const res = await patchProduct(modalProductData);
                  setInternalProductState(res);
                  onClose();
                } catch (error) {
                  // TODO: add toast
                }
              }}
            />
          </Flex>
        </Box>
      </Slide>
    </Portal>
  );
};
