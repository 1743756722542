import React, { useContext, useEffect, useRef, useState } from "react";
import ShoppingList from "../../shared/components/shopping-list";
import NewListForm from "../../shared/components/new-list-form";
import "./index.scss";
import { t } from "i18next";
import { AppContext } from "../../app/contexts/appState.context";
import { Flex, Spinner } from "@chakra-ui/react";
import { useQuery } from "react-query";
import {
  getSharedShoppingLists,
  getShoppingLists,
} from "../../shared/api/shoppingLists/shopping-lists.api";
import { ShoppingListDTO } from "../../shared/api/shoppingLists/shopping-lists.api.type";
import { useRecoilState } from "recoil";
import {
  AvailableSharedShoppingListsAtom,
  AvailableShoppingListsAtom,
} from "../../atoms";
import { getDefaultProducts } from "../../shared/api/products.api";
import { useWebSocket } from "../../shared/hooks/useWebsocket";
import { useAppState } from "../../app.state";
import { test123 } from "../../shared/api/chat-products";

// @ts-expect-error 123
window.test123 = test123;

const Home = () => {
  const holderRef = useRef<null | HTMLDivElement>(null);
  const { appState, dispatch: dispatchAppState } = useContext(AppContext);

  const [isNewListFormOpen, setIsNewListFormOpen] = useState<boolean>(false);

  // Atoms
  const [shoppingListsData, setShoppingListsData] = useRecoilState(
    AvailableShoppingListsAtom
  );
  const [sharedShoppingLists, setSharedShoppingLists] = useRecoilState(
    AvailableSharedShoppingListsAtom
  );

  const { setProducts } = useAppState();

  const { data: productsData, isLoading: productsDataLoading } = useQuery({
    queryKey: ["productsData"],
    queryFn: getDefaultProducts,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (productsData?.data) {
      setProducts(productsData.data);
    }
  }, [productsData]);

  const {
    data: ShoppingListsData,
    isLoading: ShoppingListsLoading,
    refetch: refetchList,
  } = useQuery({
    queryKey: ["ShoppingLists"],
    queryFn: getShoppingLists,
    refetchOnWindowFocus: false,
  });

  const {
    data: SharedShoppingListsData,
    isLoading: SharedShoppingListsLoading,
    refetch: refetchSharedList,
  } = useQuery({
    queryKey: ["SharedShoppingLists"],
    queryFn: getSharedShoppingLists,
    refetchOnWindowFocus: false,
  });

  const { messages, sendMessage } = useWebSocket();

  const refetchBoth = async () => {
    await refetchSharedList();
    await refetchList();
  };
  useEffect(() => {
    refetchBoth();
  }, [messages]);

  useEffect(() => {
    if (ShoppingListsLoading || SharedShoppingListsLoading) return;
    setShoppingListsData(ShoppingListsData?.data ?? []);
    setSharedShoppingLists(SharedShoppingListsData?.data ?? []);
  }, [
    ShoppingListsData,
    setShoppingListsData,
    SharedShoppingListsLoading,
    ShoppingListsLoading,
  ]);

  useEffect(() => {
    if (!appState.isAnyListOpened) {
      dispatchAppState({
        type: "SET_FLOATING_BUTTON",
        data: {
          ...appState.floatingButtonState,
          callback: () => setIsNewListFormOpen(true),
        },
      });
    }
  }, [appState.isAnyListOpened]);
  const listsCount = shoppingListsData.length + sharedShoppingLists.length ?? 0;
  return (
    <Flex flexDirection="column" maxHeight="90vh" mt="70px" w="100%">
      <div className="ShoppingListsHolder" ref={holderRef}>
        <div className="ShoppingListsHolder__count">
          <h3>{t("Your lists")}</h3>
          <span>{listsCount}</span>
        </div>
        <div className="ShoppingListsHolder__inner">
          {!ShoppingListsLoading || !SharedShoppingListsLoading ? (
            shoppingListsData?.map((shoppingList: ShoppingListDTO) => {
              return (
                <ShoppingList
                  key={shoppingList.id}
                  data={shoppingList}
                  ref={holderRef}
                  sendMessage={sendMessage}
                  refetchLists={refetchBoth}
                />
              );
            })
          ) : (
            <Spinner />
          )}
        </div>
      </div>
      <div className="ShoppingListsHolder" ref={holderRef}>
        <div className="ShoppingListsHolder__count">
          <h3>{t("Lists shared with you")}</h3>
          <span>{listsCount}</span>
        </div>
        <div className="ShoppingListsHolder__inner">
          {!ShoppingListsLoading || !SharedShoppingListsLoading ? (
            [...sharedShoppingLists]?.map((shoppingList: ShoppingListDTO) => {
              return (
                <ShoppingList
                  key={shoppingList.id}
                  data={shoppingList}
                  refetchLists={refetchBoth}
                  ref={holderRef}
                  sendMessage={sendMessage}
                />
              );
            })
          ) : (
            <Spinner />
          )}
        </div>
      </div>
      <NewListForm
        isOpen={isNewListFormOpen}
        setIsNewListFormOpen={setIsNewListFormOpen}
      />
    </Flex>
  );
};

export default Home;
