import {
  FaApple,
  FaBaby,
  FaBasketballBall,
  FaBreadSlice,
  FaBroom,
  FaCheese,
  FaCoffee,
  FaCookie,
  FaFish,
  FaHome,
  FaMedkit,
  FaOilCan,
  FaPaw,
  FaPepperHot,
  FaPlaystation,
  FaPlus,
  FaSnowflake,
  FaTshirt,
  FaTv,
} from "react-icons/fa";

export enum ProductCategories {
  FRUITS_AND_VEGETABLES = "FRUITS_AND_VEGETABLES",
  DAIRY_PRODUCTS = "DAIRY_PRODUCTS",
  MEAT_AND_POULTRY = "MEAT_AND_POULTRY",
  SEAFOOD = "SEAFOOD",
  BAKERY_ITEMS = "BAKERY_ITEMS",
  FROZEN_FOODS = "FROZEN_FOODS",
  BEVERAGES = "BEVERAGES",
  SNACKS = "SNACKS",
  CANNED_GOODS = "CANNED_GOODS",
  SPICES_AND_CONDIMENTS = "SPICES_AND_CONDIMENTS",
  GRAINS_AND_CEREALS = "GRAINS_AND_CEREALS",
  PERSONAL_CARE = "PERSONAL_CARE",
  CLEANING_SUPPLIES = "CLEANING_SUPPLIES",
  PET_SUPPLIES = "PET_SUPPLIES",
  HEALTH_AND_WELLNESS = "HEALTH_AND_WELLNESS",
  BABY_PRODUCTS = "BABY_PRODUCTS",
  HOME_ESSENTIALS = "HOME_ESSENTIALS",
  ELECTRONICS = "ELECTRONICS",
  CLOTHING_AND_ACCESSORIES = "CLOTHING_AND_ACCESSORIES",
  SPORTS_EQUIPMENT = "SPORTS_EQUIPMENT",
}

export const ProductCategoriesIdMapper = {
  "6754119335520debb8299aab": ProductCategories.FRUITS_AND_VEGETABLES,
  "6754119c35520debb8299aad": ProductCategories.DAIRY_PRODUCTS,
  "675411a235520debb8299aaf": ProductCategories.MEAT_AND_POULTRY,
  "675411a835520debb8299ab1": ProductCategories.SEAFOOD,
  "675411af35520debb8299ab3": ProductCategories.BAKERY_ITEMS,
  "675411b535520debb8299ab5": ProductCategories.FROZEN_FOODS,
  "675411ba35520debb8299ab7": ProductCategories.BEVERAGES,
  "675411c035520debb8299ab9": ProductCategories.SNACKS,
  "675411c735520debb8299abb": ProductCategories.CANNED_GOODS,
  "675411cd35520debb8299abd": ProductCategories.SPICES_AND_CONDIMENTS,
  "675411d435520debb8299abf": ProductCategories.GRAINS_AND_CEREALS,
  "675411da35520debb8299ac1": ProductCategories.PERSONAL_CARE,
  "675411e135520debb8299ac3": ProductCategories.CLEANING_SUPPLIES,
  "675411e835520debb8299ac5": ProductCategories.PET_SUPPLIES,
  "675411ee35520debb8299ac7": ProductCategories.HEALTH_AND_WELLNESS,
  "675411f535520debb8299ac9": ProductCategories.BABY_PRODUCTS,
  "675411fc35520debb8299acb": ProductCategories.HOME_ESSENTIALS,
  "6754120235520debb8299acd": ProductCategories.ELECTRONICS,
  "6754120935520debb8299acf": ProductCategories.CLOTHING_AND_ACCESSORIES,
  "6754121035520debb8299ad1": ProductCategories.SPORTS_EQUIPMENT,
};

export const ProductCategoriesMapper = {
  [ProductCategories.FRUITS_AND_VEGETABLES]: "6754119335520debb8299aab",
  [ProductCategories.DAIRY_PRODUCTS]: "6754119c35520debb8299aad",
  [ProductCategories.MEAT_AND_POULTRY]: "675411a235520debb8299aaf",
  [ProductCategories.SEAFOOD]: "675411a835520debb8299ab1",
  [ProductCategories.BAKERY_ITEMS]: "675411af35520debb8299ab3",
  [ProductCategories.FROZEN_FOODS]: "675411b535520debb8299ab5",
  [ProductCategories.BEVERAGES]: "675411ba35520debb8299ab7",
  [ProductCategories.SNACKS]: "675411c035520debb8299ab9",
  [ProductCategories.CANNED_GOODS]: "675411c735520debb8299abb",
  [ProductCategories.SPICES_AND_CONDIMENTS]: "675411cd35520debb8299abd",
  [ProductCategories.GRAINS_AND_CEREALS]: "675411d435520debb8299abf",
  [ProductCategories.PERSONAL_CARE]: "675411da35520debb8299ac1",
  [ProductCategories.CLEANING_SUPPLIES]: "675411e135520debb8299ac3",
  [ProductCategories.PET_SUPPLIES]: "675411e835520debb8299ac5",
  [ProductCategories.HEALTH_AND_WELLNESS]: "675411ee35520debb8299ac7",
  [ProductCategories.BABY_PRODUCTS]: "675411f535520debb8299ac9",
  [ProductCategories.HOME_ESSENTIALS]: "675411fc35520debb8299acb",
  [ProductCategories.ELECTRONICS]: "6754120235520debb8299acd",
  [ProductCategories.CLOTHING_AND_ACCESSORIES]: "6754120935520debb8299acf",
  [ProductCategories.SPORTS_EQUIPMENT]: "6754121035520debb8299ad1",
};

export const ProductNamesMapper = {
  [ProductCategories.FRUITS_AND_VEGETABLES]: "Fruits and Vegetables",
  [ProductCategories.DAIRY_PRODUCTS]: "Dairy Products",
  [ProductCategories.MEAT_AND_POULTRY]: "Meat and Poultry",
  [ProductCategories.SEAFOOD]: "Seafood",
  [ProductCategories.BAKERY_ITEMS]: "Bakery Items",
  [ProductCategories.FROZEN_FOODS]: "Frozen Foods",
  [ProductCategories.BEVERAGES]: "Beverages",
  [ProductCategories.SNACKS]: "Snacks",
  [ProductCategories.CANNED_GOODS]: "Canned Goods",
  [ProductCategories.SPICES_AND_CONDIMENTS]: "Spices and Condiments",
  [ProductCategories.GRAINS_AND_CEREALS]: "Grains and Cereals",
  [ProductCategories.PERSONAL_CARE]: "Personal Care",
  [ProductCategories.CLEANING_SUPPLIES]: "Cleaning Supplies",
  [ProductCategories.PET_SUPPLIES]: "Pet Supplies",
  [ProductCategories.HEALTH_AND_WELLNESS]: "Health and Wellness",
  [ProductCategories.BABY_PRODUCTS]: "Baby Products",
  [ProductCategories.HOME_ESSENTIALS]: "Home Essentials",
  [ProductCategories.ELECTRONICS]: "Electronics",
  [ProductCategories.CLOTHING_AND_ACCESSORIES]: "Clothing and Accessories",
  [ProductCategories.SPORTS_EQUIPMENT]: "Sports Equipment",
};

export const ICONS: any = {
  [ProductCategories.FRUITS_AND_VEGETABLES]: <FaApple />,
  [ProductCategories.DAIRY_PRODUCTS]: <FaCheese />,
  [ProductCategories.MEAT_AND_POULTRY]: <FaCheese />,
  [ProductCategories.SEAFOOD]: <FaFish />,
  [ProductCategories.BAKERY_ITEMS]: <FaBreadSlice />,
  [ProductCategories.FROZEN_FOODS]: <FaSnowflake />,
  [ProductCategories.BEVERAGES]: <FaCoffee />,
  [ProductCategories.SNACKS]: <FaCookie />,
  [ProductCategories.CANNED_GOODS]: <FaOilCan />,
  [ProductCategories.SPICES_AND_CONDIMENTS]: <FaPepperHot />,
  [ProductCategories.GRAINS_AND_CEREALS]: <FaBreadSlice />,
  [ProductCategories.PERSONAL_CARE]: <FaPlaystation />,
  [ProductCategories.CLEANING_SUPPLIES]: <FaBroom />,
  [ProductCategories.PET_SUPPLIES]: <FaPaw />,
  [ProductCategories.HEALTH_AND_WELLNESS]: <FaMedkit />,
  [ProductCategories.BABY_PRODUCTS]: <FaBaby />,
  [ProductCategories.HOME_ESSENTIALS]: <FaHome />,
  [ProductCategories.ELECTRONICS]: <FaTv />,
  [ProductCategories.CLOTHING_AND_ACCESSORIES]: <FaTshirt />,
  [ProductCategories.SPORTS_EQUIPMENT]: <FaBasketballBall />,
  new: <FaPlus />,
};
