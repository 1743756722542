import { atom } from "recoil";
import { ProductsGET } from "./shared/api/products.api.type";
import { ProductInListDTO } from "./shared/api/productInList/product-in-list.api.type";
import { ShoppingListDTO } from "./shared/api/shoppingLists/shopping-lists.api.type";

/**
 * Holds fetched owned shopping list from the backend
 * Could be updated on new list created
 */
export const AvailableShoppingListsAtom = atom({
  key: "AvailableShoppingListsAtom",
  default: [] as ShoppingListDTO[],
});

/**
 * Holds fetched shared shopping list from the backend
 * Could be updated on new list created
 */
export const AvailableSharedShoppingListsAtom = atom({
  key: "AvailableSharedShoppingListsAtom",
  default: [] as ShoppingListDTO[],
});
