import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ProductInListDTO } from "../../../../api/productInList/product-in-list.api.type";

type UseShoppingListElementProps = {
  productData: any;
};

type UseShoppingListElementReturn = {
  internalProductState: ProductInListDTO | null;
  setInternalProductState: Dispatch<SetStateAction<ProductInListDTO | null>>;
  isEditModalShown: boolean;
  setIsEditModalShown: Dispatch<SetStateAction<boolean>>;
  openModal: () => void;
  closeModal: () => void;
};

export const useShoppingListElement = ({
  productData,
}: UseShoppingListElementProps): UseShoppingListElementReturn => {
  const [internalProductState, setInternalProductState] =
    useState<ProductInListDTO | null>(productData);
  const [isEditModalShown, setIsEditModalShown] = useState<boolean>(false);

  useEffect(() => {
    setInternalProductState(productData);
  }, [productData]);

  const closeModal = () => setIsEditModalShown(false);
  const openModal = () => setIsEditModalShown(true);

  return {
    internalProductState,
    setInternalProductState,
    isEditModalShown,
    setIsEditModalShown,
    openModal,
    closeModal,
  };
};
