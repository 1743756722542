import { MdDeleteOutline, MdEdit } from "react-icons/md";
import HeaderOptions from "../header-options";
import dayjs from "dayjs";
import { useContext, useRef } from "react";
import { useShoppingListHeader } from "./use-shopping-header";
import { AppContext } from "../../../../../app/contexts/appState.context";
import { ShoppingListDTO } from "../../../../api/shoppingLists/shopping-lists.api.type";
import { useShoppingListState } from "../../shoppint-list.state";

type ShoppingListHeaderProps = {
  isFocused: boolean;
  listData: ShoppingListDTO;
  setIsEditListModeOn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFullScreenActive: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddProductModeActive: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: () => void;
};

export const ShoppingListHeader = ({
  isFocused,
  listData,
  setIsEditListModeOn,
  setIsFullScreenActive,
  setIsFocused,
  setIsAddProductModeActive,
  onDelete,
}: ShoppingListHeaderProps): JSX.Element => {
  const innerHolderTouchRef = useRef<HTMLDivElement>(null);
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { setProductsInShoppingList } = useShoppingListState();
  const { touchStart, setTouchStart, touchEnd, setTouchEnd } =
    useShoppingListHeader();

  const onTouchStart = (e: any) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (touchStart && innerHolderTouchRef.current) {
      const currentPosition = e.targetTouches[0].clientX;
      const numericValue = Math.abs(
        parseFloat(
          window
            .getComputedStyle(innerHolderTouchRef.current)
            .getPropertyValue("transform")
            .split("(")[1]
            .split(")")[0]
            .split(",")[4]
        )
      );
      const distanceScroll = currentPosition - touchStart || numericValue;

      if (
        distanceScroll < 0 &&
        numericValue < 110 &&
        innerHolderTouchRef.current
      ) {
        e.stopPropagation();
        e.preventDefault();
        setTouchEnd(currentPosition);
        innerHolderTouchRef.current.style.transform = `translateX(${distanceScroll}px)`;
      } else if (distanceScroll > 0) {
        e.stopPropagation();
        e.preventDefault();
        innerHolderTouchRef.current.style.transform = `translateX(0px)`;
      }
    }
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 0;

    if (innerHolderTouchRef.current) {
      if (isLeftSwipe) {
        if (distance > 70) {
          innerHolderTouchRef.current.style.transform = `translateX(-120px)`;
        } else {
          innerHolderTouchRef.current.style.transform = `translateX(0px)`;
        }
      } else {
        innerHolderTouchRef.current.style.transform = `translateX(0px)`;
      }
    }
  };

  return (
    <div
      className="ShoppingList__header"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onClick={(e) => {
        if (isFocused) {
          e.stopPropagation();
          setProductsInShoppingList([]);
          setIsFullScreenActive(false);
          dispatchAppState({
            type: "SET_LIST_IS_UN_OPEN",
          });
          setIsFocused(false);
          setIsAddProductModeActive(false);
        }
      }}
    >
      <div
        ref={innerHolderTouchRef}
        className="ShoppingList__header__innerHolder"
      >
        <div className="ShoppingList__header__infoHolder">
          <h4>{listData.list_name}</h4>
          <span className="ShoppingList__header__creationDate">
            {dayjs(listData.created).format("DD.MM.YYYY")}
          </span>
          <HeaderOptions
            sharedWith={listData.shared_with}
            shoppingListDocumentReference={listData.id}
          />
        </div>
        <button
          className="ShoppingList__header__deleteButton"
          onClick={async (e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <MdDeleteOutline size={20} color="white" />
        </button>
        <button
          className="ShoppingList__header__editButton"
          onClick={async (e) => {
            e.stopPropagation();
            setIsEditListModeOn(true);
            dispatchAppState({
              type: "SET_LIST_IS_OPEN",
            });
            if (innerHolderTouchRef.current) {
              innerHolderTouchRef.current.style.transform = `translateX(0px)`;
            }
          }}
        >
          <MdEdit size={16} color="white" />
        </button>
      </div>
    </div>
  );
};
