import { useState } from "react";
import { ProductInListDTO } from "../../api/productInList/product-in-list.api.type";

export const useShoppingList = (products: any) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFullscreenActive, setIsFullScreenActive] = useState<boolean>(false);
  const [isAddProductModeActive, setIsAddProductModeActive] =
    useState<boolean>(false);
  const [newProductsInList, setNewProductsInList] = useState<{
    [key: string]: ProductInListDTO;
  }>({});
  const [isEditListModeOn, setIsEditListModeOn] = useState<boolean>(false);
  const [internalProductInList, setInternalProductsInList] = useState(products);

  return {
    isFocused,
    setIsFocused,
    isFullscreenActive,
    setIsFullScreenActive,
    isAddProductModeActive,
    setIsAddProductModeActive,
    newProductsInList,
    setNewProductsInList,
    isEditListModeOn,
    setIsEditListModeOn,
    internalProductInList,
    setInternalProductsInList,
  };
};
