import { AiOutlinePlus } from "react-icons/ai";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { AppContext } from "../../../app/contexts/appState.context";
import { useLocation } from "react-router-dom";

const PlusButtonMenu = () => {
  const { appState } = useContext(AppContext);
  const { pathname } = useLocation();

  const site = pathname.split("/")[1];

  // Show only button on home page /
  if (site) {
    return null;
  }

  return (
    <Flex
      className="PlusButtonMenu"
      position="fixed"
      bottom="38px"
      left="50%"
      transform="translateX(-50%)"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      flexWrap="wrap"
      gap={4}
      zIndex={1}
    >
      <Button
        bg="brand.500"
        _hover={{ bg: "brand.500" }}
        w="50px"
        h="50px"
        borderRadius="50%"
        color="white"
        fontSize="24px"
        onClick={appState.callback}
      >
        {appState.icon ?? <AiOutlinePlus size={24} />}
      </Button>
    </Flex>
  );
};

export default PlusButtonMenu;
