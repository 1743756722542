import { atom, SetterOrUpdater } from "recoil";

import { useRecoilState } from "recoil";
import { ProductInListDTO } from "../../api/productInList/product-in-list.api.type";

/**
 * Current opened list products
 */
export const CurrentOpenedListProductsAtom = atom({
  key: "CurrentOpenedListProductsAtom",
  default: [] as ProductInListDTO[],
});

/**
 * Holds product changes made in product search
 * What products has been deleted or added
 */
const defaultProductSearchChanges = {
  deleted: new Set<string>(),
  added: new Set<string>(),
};

export const ProductSearchChangesAtom = atom({
  key: "ProductSearchChangesAtom",
  default: defaultProductSearchChanges,
});

type UseShoppingListStateReturn = {
  productsInShoppingList: ProductInListDTO[];
  setProductsInShoppingList: SetterOrUpdater<ProductInListDTO[]>;
  productSearchChanges: typeof defaultProductSearchChanges;
  setProductSearchChanges: SetterOrUpdater<typeof defaultProductSearchChanges>;
};

export const useShoppingListState = (): UseShoppingListStateReturn => {
  const [productsInShoppingList, setProductsInShoppingList] = useRecoilState(
    CurrentOpenedListProductsAtom
  );
  const [productSearchChanges, setProductSearchChanges] = useRecoilState(
    ProductSearchChangesAtom
  );
  return {
    productsInShoppingList,
    setProductsInShoppingList,
    productSearchChanges,
    setProductSearchChanges,
  };
};
