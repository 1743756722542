import { Box, Flex, Text } from "@chakra-ui/react";
import { ICONS } from "../../../../variables/product-types.variables";

type NewProductSearchInputProps = {
  productSearchValue: string;
  setShowNewProductModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const NewProductSearchInput = ({
  productSearchValue,
  setShowNewProductModal,
}: NewProductSearchInputProps) => {
  return (
    <Flex
      gap="10px"
      alignItems="center"
      h="20px"
      w="100%"
      padding="20px 5px"
      onClick={() => setShowNewProductModal(true)}
    >
      {ICONS?.["new"]}
      <Text>{productSearchValue}</Text>
    </Flex>
  );
};

export default NewProductSearchInput;
