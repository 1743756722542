import { useMemo } from "react";
import { useAppState } from "../../app.state";

type UseProductProps = {
  productData: any;
};

export const useProduct = ({ productData }: UseProductProps) => {
  const { products } = useAppState();

  const productToUse = useMemo(
    () =>
      products.find((product) => product.id === productData?.productReference),
    []
  );

  return {
    productToUse,
  };
};
